import React, {useMemo, useState} from 'react';

export function useHiddenObjectsState(hiddenObjectScenes) {

	const [sceneIndex, setSceneIndex] = useState(0);
	const [gameState, setGameState] = useState('intro');
	const [foundObjects, setFoundObjects] = useState([]);
	const [helpTimeout, setHelpTimeout] = useState(0);
	const [highlightedObjectIndex, setHighlightedObjectIndex] = useState(null);
	const [movingStarStartPosition, setMovingStarStartPosition] = useState(null);

	const scenes = useMemo(() => {
		return [...hiddenObjectScenes].sort((a, b) => +a.displayOrder - +b.displayOrder);
	}, hiddenObjectScenes);

	const currentScene = scenes[sceneIndex];

	const hiddenObjects = useMemo(() => {
		return shuffleArray(getHiddenObjects(currentScene.hiddenObjects));
	}, [currentScene]);

	const hiddenObjectsAvailable = useMemo(() => {
		return hiddenObjects
			.filter(({name}) => !foundObjects.includes(name));
	}, [hiddenObjects, foundObjects]);


	return {
		clickObject({name, x, y}) {
			setHighlightedObjectIndex(null);
			if (!movingStarStartPosition) {
				setMovingStarStartPosition({
					startTop: y - 7,
					startLeft: x - 7,
					name,
					endTop: 12,
					endLeft: 80 + (32 * foundObjects.length),
				});
			}
		},
		onStarMoved() {
			if (movingStarStartPosition) {
				setFoundObjects(foundObjects.concat(movingStarStartPosition.name));
				setMovingStarStartPosition(null);
				if (foundObjects.length === hiddenObjects.length - 1) {
					setGameState('sceneCompleted');
				}
			}
		},
		hiddenObjectsAvailable,
		objectsAvailable: hiddenObjects.length,
		foundObjects: foundObjects.length,
		currentScene,
		helpTimeout,
		helpMe() {
			if (helpTimeout) return;

			setHelpTimeout(30);

			setHighlightedObjectIndex(0);

			function reduceTimeout() {
				setHelpTimeout(old => {
					const newTimeout = old - 1;

					if (newTimeout) {
						setTimeout(reduceTimeout, 1000);
					} else {
						setHighlightedObjectIndex(null);
					}

					return newTimeout;
				});
			}

			setTimeout(reduceTimeout, 1000);
		},
		highlightedObjectIndex,
		nextScene() {
			if (sceneIndex === scenes.length - 1) {
				setGameState('completed');
			} else {
				setSceneIndex(sceneIndex + 1);
				setFoundObjects([]);
				setHighlightedObjectIndex(null);
				setGameState('playing');
			}
		},
		movingStarStartPosition,
		start() {
			setSceneIndex(0);
			setFoundObjects([]);
			setHighlightedObjectIndex(null);
			setHelpTimeout(0);
			setGameState('playing');
		},
		gameState,
	};
}

function getHiddenObjects(hiddenObjects) {
	if (typeof DOMParser === 'undefined') {
		return []; // SSR
	}

	const parser = new DOMParser();

	const objects = [];
	for (const hiddenObject of hiddenObjects) {
		const doc = parser.parseFromString(`<svg>${hiddenObject.SVG}/</svg>`, 'application/xml');

		if (doc.getElementsByTagName('parsererror').length) {
			console.error(doc.getElementsByTagName('parsererror')[0].outerHTML);
		}
		const paths = doc.getElementsByTagName('path');

		if (paths.length > 0) {
			const path = paths[0];
			const d = path.getAttribute('d');

			objects.push({
				d,
				name: hiddenObject.title,
				soundURL: hiddenObject.soundFile?.localFile.publicURL,
			});
		}
	}

	return objects;
}

function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}

	return array;
}
