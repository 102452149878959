import React, {useCallback, useEffect, useState} from 'react';
import RoundButton from './RoundButton';

export function AudioPlayButton({src, hasCaption = true, isSecondaryBtn = false, btnHasDropShadow = true, className="mr-3"}) {
	const [, forceUpdate] = React.useState({});

	const hasAudio = !!src;
	const [audio, setAudio] = useState();

	const playPause = useCallback(() => {
		if (!audio) {
			// load on demand
			const audio = new Audio(src);
			setAudio(audio);
			audio.onended = () => {
				forceUpdate({});
			}
			audio.play();
		} else if (audio.paused) {
			audio.play();
		} else {
			audio.pause();
		}
		forceUpdate({});
	}, [audio, src, forceUpdate]);

	useEffect(() => {
		return () => {
			// stop audio when leaving page
			audio?.pause();
			if (audio) {
				setAudio(null);
			}
		};
	}, [audio, src, setAudio]);

	if (!hasAudio) {
		return null;
	}

	return (
		<RoundButton
			isSecondaryBtn={isSecondaryBtn}
			caption={audio?.paused !== false ? 'Listen' : 'Pause'}
			hasCaption={hasCaption}
			btnHasDropShadow={btnHasDropShadow}
			icon={audio?.paused !== false ? 'listen' : 'pause'}
			className={className}
			onClick={playPause}
		/>
	);
}
