import React from 'react';
import Star from 'react-bootstrap-icons/dist/icons/star';
import StarFill from 'react-bootstrap-icons/dist/icons/star-fill';

export function SceneProgressBar({now, max}) {
	const stars = [];

	for (let i = 0; i < max; i++) {
		if (i < now) {
			stars.push(<StarFill key={i} color="yellow" size="2em" />);
		} else {
			stars.push(<Star key={i} color="yellow" size="2em" />);
		}
	}


	return <div className="d-inline-block">{stars}</div>;
}
