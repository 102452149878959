import {HiddenObjectSvg} from './HiddenObjectsSvg';
import {MovingStar} from './MovingStar';
import React from 'react';
import Logo from '../components/Logo';
import {SceneProgressBar} from './SceneProgressBar';
import HouseFill from 'react-bootstrap-icons/dist/icons/house-fill';
import {Link, Link as RSLink} from 'gatsby';
import {AudioPlayButton} from '../components/AudioPlayButton';
import {Helmet} from "react-helmet";

export function Scene({game}) {
	const currentObject = game.hiddenObjectsAvailable[0];

	return <>
		<header className="header-area">
			<Helmet>
				<meta name="apple-mobile-web-app-capable" content="yes"/>
				<meta name="mobile-web-app-capable" content="yes"/>
			</Helmet>
			<div className="header-grid">
				<div className="logo-box">
					<Link to="/">
						<Logo flipped={true}/>
					</Link>
				</div>
				<div className="score-box">
					<SceneProgressBar now={game.foundObjects} max={game.objectsAvailable}/>
				</div>
				<div className="game-title-box">
					{game.currentScene.title}
				</div>
				<div className="items-sound-wrap">
					<AudioPlayButton src={game.currentScene.questionAudio?.localFile.publicURL} hasCaption={false}
													 isSecondaryBtn={false} btnHasDropShadow={false} className=""/>
				</div>
				<div className="home-link-box">
					<RSLink to="/">
						<HouseFill/>
					</RSLink>
				</div>
			</div>
		</header>
		<div className="game-box">
			<div className="game-box-inner">
				<div className="content">
					<div className="img-wrap">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 1024 575"
							preserveAspectRatio="xMinYMin meet"
							style={{maxHeight: '100%', maxWidth: '100%'}}
						>
							<image width="1024" height="575" xlinkHref={game.currentScene.image.localFile.publicURL}/>
							<HiddenObjectSvg
								objects={game.hiddenObjectsAvailable}
								onClickObject={game.clickObject}
								highlightedObjectIndex={game.highlightedObjectIndex}
							/>
						</svg>
						{game.movingStarStartPosition && <MovingStar game={game}/>}
					</div>
				</div>
			</div>
		</div>
		<section className="bottom-game-panel">
			<div className="bottom-panel-grid">
				<div className="box-one">
					Find:
				</div>
				<div className="item-names-box">
					<div className="items-wrap">
						{!!currentObject && <>
							<div className="item">{currentObject.name}</div>
						</>}
					</div>
					<div className="items-sound-wrap">
						{!!currentObject && <>
							<AudioPlayButton src={currentObject.soundURL}  hasCaption={false}
															 isSecondaryBtn={false} btnHasDropShadow={false} className=""/>
						</>}
					</div>
				</div>
				<div className="help-box">
					{!game.helpTimeout &&
						<div className="btn-game-help" onClick={game.helpMe}>
							Help me&nbsp;
							<span className="game-help-status-ind green"/>
						</div>}
					{!!game.helpTimeout &&
						<div className="btn-game-help">Next help in <span
							className="game-help-status-ind red">{game.helpTimeout}</span> seconds
						</div>}
				</div>
			</div>
		</section>
	</>;
}
