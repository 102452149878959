import React from 'react';
import Logo from '../components/Logo';
import Menu from '../components/Menu';
import RoundButton from '../components/RoundButton';
import Sponsor from '../components/Sponsor';
import {Markdown} from '../components/Markdown';
import {Link} from "gatsby";
import {AudioPlayButton} from '../components/AudioPlayButton';

export function CompletedScene({game}) {
	return <>
		<header className="header-area">
			<div className="container">
				<div className="header-grid">
					<div className="box-1">
						<Link to="/">
							<Logo/>
						</Link>
					</div>
					<div className="box-2 h-align-center">
					</div>
					<Menu />
				</div>
			</div>
		</header>
		<div className="game-box">
			<div className="game-box-inner" style={{paddingTop: "90px"}}>
				<div className="content" >
{/*
					<div className="cols-100 mb-2">
						<div className="left-col v-align-center">
							<div className="row">
								<div className="col-10 d-flex v-align-center">
									<h3>{game.currentScene.completedTitle}</h3>
								</div>
								<div className="col-2">
									<img width="75" height="75" src={game.currentScene.statusImage.localFile.publicURL} alt="Cartoon face of smiling girl"
											 className="cartoon-face-smiling-girl" />
								</div>
							</div>
						</div>
					</div>
*/}

					<div className="cols-66-33">
						<div className="left-col tight-text">
							<h3>{game.currentScene.completedTitle}</h3>
							<Markdown>
								{game.currentScene.completedMessage}
							</Markdown>
							<div className="completed-subtitle">{game.currentScene.completedSubTitle}</div>
						</div>

						<div className="right-col">
							<div style={{textAlign: "right"}}><img width="75" height="75" src={game.currentScene.statusImage.localFile.publicURL} alt="Cartoon face of smiling girl"
									 className="cartoon-face-smiling-girl" /></div><br/>
							<AudioPlayButton src={game.currentScene.completedAudio?.localFile.publicURL} hasCaption={true}
															 isSecondaryBtn={false} />

							<RoundButton
								caption="Next"
								icon="play"
								isSecondaryBtn={true}
								onClick={game.nextScene}
							/>
						</div>
					</div>
				</div>
				{/* / .content */}
			</div>
			{/* / .game-box-inner */}
		</div>
		{/* / .game-box */}
	</>
	;
}
