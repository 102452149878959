import React from 'react';
import {useHiddenObjectsState} from './useHiddenObjectsState';
import './hiddenObjectsGame.css';
import {Intro} from './Intro';
import {CompletedScene} from './CompletedScene';
import {GameCompleted} from './CompletedGame';
import {Scene} from './Scene';
import {Helmet} from 'react-helmet';
import Page from '../components/Page';

export default function Game({data, puzzleScenes}) {

	const game = useHiddenObjectsState(puzzleScenes);

	let content;
	let page = 'general-page';


	switch (game.gameState) {
		//switch ('completed') {
		case 'playing':
			page = 'game-page';
			content = <Scene game={game}/>;
			break;
		case 'sceneCompleted':
			content = <CompletedScene game={game}/>;
			break;
		case 'intro':
			content = <Intro game={game} data={data}/>;
			break;
		case 'completed':
			content = <GameCompleted game={game} data={data}/>;
			break;
	}

	if (game.gameState === 'intro') {
		return <Page page={page} orientation="portrait">
			<Helmet>
				<html className="orient-portrait"/>
			</Helmet>
			{content}
		</Page>;
	} else {
		return <Page page={page} orientation="landscape">
			<Helmet>
				<html className="orient-landscape"/>
			</Helmet>
			{content}
		</Page>;
	}
}
