import React from 'react';
import Game from '../hiddenObjectsGame/Game';
import {graphql} from 'gatsby';

export default function GamePage({data: {game, allPuzzleScene: {nodes: puzzleScenes}}}) {
	return (
		<Game
			data={game}
			puzzleScenes={puzzleScenes}
		/>
	);
}

export const query = graphql`
    query {
		allPuzzleScene(filter: {active: {eq: true}, published: {eq: true}}) {
			nodes {
				name
				title
				image {
					localFile {
						publicURL
					}
				}
				displayOrder
				completedTitle
				completedSubTitle
				completedMessage
				completedAudio {
					localFile {
						publicURL
					}
				}
				questionAudio {
					localFile {
						publicURL
					}
				}				
				statusImage {
					localFile {
						publicURL
					}
				}
				hiddenObjects {
					title
					SVG
					soundFile {
						localFile {
							publicURL
						}
					}
				}
			}
		}

		game {
			introTitle
			introText
			introAudio {
				localFile {
					publicURL
				}
			}
			completedText
			completedAudio {
				localFile {
					publicURL
				}
			}
			completedStatusImage {
				localFile {
					publicURL
				}
			}
			completedVideo
		}
	}
`;
