import React, {useState} from 'react';
import {Transition} from 'react-transition-group';
import StarFill from 'react-bootstrap-icons/dist/icons/star-fill';

const time = 750;
export function MovingStar({game: {movingStarStartPosition, onStarMoved}}) {
	const [_in, setIn] = useState(true);
	return (
		<Transition
			in={_in}
			appear
			onEntered={() => setIn(false)}
			timeout={{appear: 0, exit: time}}
			onExited={onStarMoved}
		>
			{state => (
				<StarFill
					color="yellow"
					size="2em"
					style={{
						position: 'absolute',
						top: state === 'exiting' ? movingStarStartPosition.endTop : movingStarStartPosition.startTop,
						left: state === 'exiting' ? movingStarStartPosition.endLeft : movingStarStartPosition.startLeft,
						transition: `top ${time}ms, left ${time}ms`,
						zIndex: 900000,
					}}
				/>
			)}
		</Transition>
	);
}
