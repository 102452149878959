import React from 'react';
import Menu from '../components/Menu';
import Logo from '../components/Logo';
import Sponsor from '../components/Sponsor';
import RoundButton from '../components/RoundButton';
import {Markdown} from '../components/Markdown';
import {Link} from "gatsby";
import {AudioPlayButton} from '../components/AudioPlayButton';
import VideoPlayer from "../components/VideoPlayer";

export function Intro({data, game}) {
	return <>
		<div className="game-box">
			<div className="game-box-inner">
				<header className="header-area">
					<div className="container">
						<div className="header-grid">
							<div className="box-1">
							</div>
							<div className="box-2"/>

							<Menu/>
						</div>
					</div>
				</header>

				<div className="content" style={{paddingBottom: 91.3333}}>
					<div className="gen-page nav-grid">
						<div className="logo-box">
							<Link to="/">
								<Logo/>
							</Link>
						</div>
						<div className="button-box">
							<a href="https://shop.keenkids.com.au" target="_blank" className="kk-btn-rounded btn-primary3">Shop</a>
						</div>
					</div>


					<h1>{data.introTitle}</h1>
					<Markdown>{data.introText}</Markdown>

					<p>If you are on a mobile device, please rotate your phone after you tap the "<strong>Play the game</strong>" button below.</p>

					<div className="a-center" style={{marginTop: '20px'}}>
						<AudioPlayButton src={data.introAudio?.localFile.publicURL} hasCaption={true}
														 isSecondaryBtn={false}/>

						<RoundButton
							caption="Play the game"
							icon="play"
							onClick={game.start}
							isSecondaryBtn={true}
						/>
					</div>
					<Sponsor/>
				</div>
				{/* / .content */}
			</div>
			{/* / .game-box-inner */}

		</div>
		{/* / .game-box */}
	</>
		;
}
