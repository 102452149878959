import React from 'react';
import PauseAudioIcon from '../assets/images/icons/icon-pause-audio.svg';
import EarIcon from '../assets/images/icons/icon-ear.svg';
import PointUpIcon from '../assets/images/icons/icon-point-up.svg';

export default function RoundButton({isSecondaryBtn = false, hasCaption = true, btnHasDropShadow = true, className, caption, icon, onClick}) {

	let src;
	switch (icon) {
		case 'listen':
			src = EarIcon;
			break;
		case 'play':
			src = PointUpIcon;
			break;
		case 'pause':
			src = PauseAudioIcon
			break;
	}

	return (
		<a onClick={onClick}
			 className={`kk-btn-circle ${isSecondaryBtn ? 'btn-secondary1' : 'btn-primary1'} ${btnHasDropShadow ? 'w-shadow' : ''} kk-btn-sm inline  ${className}`}>
			<div className="inner">
				<div className="overlay"/>
				<img src={src} alt={caption} className="icon"/>
			</div>

			{hasCaption ? <span className="caption">{caption}</span> : ''}
		</a>
	);
}
