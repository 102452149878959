import React from 'react';
import VideoPlayer from '../components/VideoPlayer';
import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Sponsor from '../components/Sponsor';
import {Markdown} from '../components/Markdown';
import {Link} from "gatsby";
import {AudioPlayButton} from '../components/AudioPlayButton';

export function GameCompleted({data, game}) {
	return <>

		<header className="header-area">
			<div className="container">
				<div className="header-grid">
					<div className="box-1">
						<Link to="/">
							<Logo/>
						</Link>
					</div>
					<div className="box-2 h-align-center">
					</div>
					<Menu />
				</div>
			</div>
		</header>

		<div className="game-box">
			<div className="game-box-inner">
				<div className="content">
					<div className="cols-100 mb-2">
						<div className="left-col v-align-center">
							<div className="row">
								<div className="col-10 d-flex v-align-center">
									<h1>Congratulations!!</h1>
								</div>
								<div className="col-2 mb-3">
									<img width="75" height="75" src={data.completedStatusImage.localFile.publicURL} alt="Finished Game"
											 className="cartoon-face-smiling-girl" />
								</div>
							</div>

							<div className="row">
								<div className="col-5">


									<Markdown>{data.completedText}</Markdown>

								</div>
								<div className="col-2">
									<AudioPlayButton src={data.completedAudio?.localFile.publicURL} hasCaption={true}
																	 isSecondaryBtn={false} />
								</div>
								<div className="col-5">
									<div className="video-container-16-9">
										<VideoPlayer src={data.completedVideo} width="100%" height={200} />
									</div>
								</div>
							</div>
						</div>
					</div>

{/*
					<div className="video-container-16-9 mt-3">
						<VideoPlayer src={data.completedVideo} width="100%" height={200} />
					</div>
*/}
				</div>
				{/* / .content */}
			</div>
			{/* / .game-box-inner */}
		</div>
		{/* / .game-box */}
	</>
	;
}
